<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pb-0">
        <v-icon left>domain</v-icon>
        Parties
        <v-spacer />
        <v-btn class="mr-4 mt-2" @click="networkSearch()">
          <v-icon left>search</v-icon>
          Find Network Matches...
        </v-btn>
        <v-btn
          color="primary"
          class="mr-4 mt-2"
          :disabled="selectedParties.length === 0"
          @click="showInvitePartiesDialog()"
        >
          <v-icon left>mail</v-icon>
          Invite parties {{ selectedParties.length === 0 ? "" : ` (${selectedParties.length})` }}
        </v-btn>
        <v-btn color="primary" @click="addNew" class="mt-2 mr-2"> <v-icon>mdi-plus</v-icon> New Party </v-btn>
      </v-card-title>

      <div class="ml-4 mb-4">
        <v-flex>
          <v-row class="ma-0 pa-0">
            <v-col sm="12" cols="12" md="4" class="pa-0">
              <v-text-field
                v-model="searchQuery"
                prepend-icon="mdi-magnify"
                :label="searchLabel"
                @keydown="tableOptions.page = 1"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col sm="12" cols="12" md="4" class="pa-0">
              <v-select
                class="mr-4"
                prepend-icon="mdi-filter"
                v-model="selectedPartyTypeId"
                :items="partyTypeList"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>
            <v-col sm="12" cols="12" md="3" class="pa-0">
              <v-select :items="partyStatuses" class="mr-4" v-model="selectedConnectionStatus"></v-select>
            </v-col>
          </v-row>
        </v-flex>
      </div>

      <v-data-table
        :must-sort="true"
        :no-data-text="''"
        :headers="headers"
        :items="enhancedParties"
        item-key="id"
        show-select
        v-model="selectedParties"
        :loading="loading"
        :options.sync="tableOptions"
        :server-items-length="totalRecords"
        :footer-props="{
          itemsPerPageOptions: [25, 50, 75, 100, -1],
        }"
        @click:row="item => selectParty(item)"
        class="mb-16"
      >
        <template v-slot:item.icon="{ item }">
          <v-icon v-show="partyStatusService.isConnected(item)" color="green">domain</v-icon>
          <v-icon v-show="partyStatusService.isOnNetwork(item) || partyStatusService.IsConnecting(item)" color="primary"
            >domain</v-icon
          >
          <v-icon v-show="partyStatusService.isNotOnNetwork(item)">domain</v-icon>
          <v-icon v-show="partyStatusService.IsInviteSent(item)">mdi-email-open-outline</v-icon>
          <v-icon v-show="partyStatusService.isInviteExpired(item)">mdi-lock-clock </v-icon>
          <v-icon v-show="partyStatusService.isConnectionExpired(item)">mdi-lock-clock </v-icon>
        </template>

        <template v-slot:item.partyTypeName="{ item }">
          {{ partyTypeNameWithTerminology(item.partyTypeId, item.partyTypeName) }}
        </template>
        <template v-slot:item.name="{ item }">
          <div v-html="highlight(item.name, searchQuery)"></div>
        </template>
        <template v-slot:item.number="{ item }">
          <div v-html="highlight(item.number, searchQuery)"></div>
        </template>
        <template v-slot:item.phone="{ item }">
          <div v-html="highlight(item.phone, searchQuery)"></div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="mt-1">
            <PartyStatus :small="true" :party="item" :callback="doneAction" />
          </div>
        </template>
        <template v-slot:item.effectiveFromDate="{ item }">
          <span v-if="item.effectiveFromDate">{{ item.effectiveFromDate | moment(dateFormat.toUpperCase()) }}</span>
        </template>
        <template v-slot:item.effectiveThruDate="{ item }">
          <span v-if="item.effectiveThruDate">{{ item.effectiveThruDate | moment(dateFormat.toUpperCase()) }}</span>
        </template>

        <template v-slot:item.isActive="{ item }">
          {{ item.isActive ? "yes" : "no" }}
        </template>

        <template v-slot:item.companyOrgUnits="{ item }">
          <div v-html="highlight(item.companyOrgUnits, searchQuery)"></div>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="showNetworkMatchesDialog" :max-width="1000" @keydown.esc="cancel">
      <v-form ref="form">
        <v-card>
          <v-card-text class="title pt-4">{{ networkMatchesDialogTitle }}</v-card-text>
          <v-card-text>
            <LoadingProgress :loading="networkMatchesLoading" />
          </v-card-text>
          <v-card-text>
            <v-data-table :must-sort="true" :no-data-text="''" :headers="networkMatchesHeaders" :items="networkMatches">
              <template v-slot:item.actions="{ item }">
                <div class="mt-1">
                  <PartyStatus :small="true" :party="item" :callback="connectedToParty" />
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="showInviteDialog" max-width="640px">
      <v-card>
        <v-card-title>
          Invite parties
        </v-card-title>
        <v-card-text>
          <v-data-table :items="enhancedSelectedParties" item-key="id" :headers="invitePartiesHeaders">
            <template v-slot:item.name="{ item }">
              <div v-html="item.name"></div>
            </template>
            <template v-slot:item.number="{ item }">
              <div v-html="item.number"></div>
            </template>
            <template v-slot:item.inviteEmailAddress="{ item }">
              <v-text-field
                v-model="item.inviteEmailAddress"
                :maxlength="255"
                :rules="requiredRules"
              >
              </v-text-field>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="inviteParties"
            :disabled="enhancedSelectedParties.some(p => requiredRules[1](p.inviteEmailAddress) !== true)"
          >
            Invite
          </v-btn>
          <v-btn @click="showInviteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import partyService from "@/services/partyService.js";
import partyStatusService from "@/services/partyStatusService.js";
import Auth0Mixin from "../Auth0Mixin";
import filterService from "@/services/filterService.js";
import partyType from "@/constants/partyType";
import partyTypeList from "@/constants/partyTypeList";
import searchUtilsService from "@/services/searchUtilsService.js";
import localStorageService from "@/services/localStorageService.js";
import PartyStatus from "@/components/PartyStatus";
import LoadingProgress from "@/components/LoadingProgress";
import terminologyService from "@/services/terminologyService.js";
import terminology from "@/constants/terminology";
import { mapState } from "vuex";
import validationService from "@/validationRules";

const partyTypeListWithTerminology = JSON.parse(JSON.stringify(partyTypeList));

export default {
  name: "PartyList",
  mixins: [Auth0Mixin],
  components: {
    PartyStatus,
    LoadingProgress,
  },

  data() {
    return {
      requiredRules: [validationService.requiredRule, validationService.emailRule],
      searchQuery: "",
      orgUnitTerminology: "",
      headers: [
        { text: "", value: "icon", width: 40, sortable: false },
        { text: "Type", value: "partyTypeName", sortable: false },
        { text: "Name", value: "name" },
        { text: "Number", value: "number" },
        { text: "Phone", value: "phone" },
        { text: "", value: "companyOrgUnits" },
        { text: "Effective From Date", value: "effectiveFromDate" },
        { text: "Effective Thru Date", value: "effectiveThruDate" },

        { text: "Active", value: "isActive" },
        { text: "", value: "actions", sortable: false },
      ],
      networkMatchesHeaders: [
        { text: "Type", value: "partyTypeName", sortable: false },
        { text: "Name", value: "name" },
        { text: "Number", value: "number" },
        { text: "", value: "actions", sortable: false },
      ],
      invitePartiesHeaders: [
        { text: "Name", value: "name" },
        { text: "Number", value: "number" },
        { text: "Invite email", value: "inviteEmailAddress", sortable: false },
      ],
      tableOptions: {
        sortBy: ["name"],
        sortDesc: [false],
        itemsPerPage: 50,
      },
      initialized: false,
      parties: [],
      alreadySelectedParties: [],
      selectedParties: [],
      enhancedSelectedParties: [],
      showInviteDialog: false,
      totalRecords: 0,
      loading: true,
      highlight: filterService.highlight,
      currentRequest: null,
      selectedPartyTypeId: -1,
      partyTypeList: [{ id: -1, name: "- All Party Types -" }, ...partyTypeListWithTerminology],
      partyStatusService,
      showNetworkMatchesDialog: false,
      networkMatchesLoading: false,
      networkMatches: [],
      mustBeConnected: false,
      selectedConnectionStatus: "",
      partyStatuses: [
        { text: "- All Connection Statuses -", value: "" },
        { text: "Connected", value: "connected" },
        { text: "Connect", value: "onNetwork" },
        { text: "Invite", value: "notOnNetwork" },
        { text: "Invite Sent", value: "inviteSent" },
        { text: "Waiting for other party to accept", value: "connecting" },
        { text: "Invite expired", value: "inviteExpired" },
        { text: "Connection request expired", value: "connectionRequestExpired" },
      ],
    };
  },

  watch: {
    tableOptions: {
      handler() {
        this.saveFiltersAndPaging();
        this.search();
      },
      deep: true,
    },
    selectedPartyTypeId() {
      this.saveFiltersAndPaging();
      this.search();
    },
    selectedConnectionStatus() {
      this.saveFiltersAndPaging();
      this.search();
    },
    mustBeConnected() {
      this.search();
    },
    searchQuery() {
      if (this.currentRequest) {
        clearTimeout(this.currentRequest);
      }
      
      this.saveFiltersAndPaging();
      this.currentRequest = setTimeout(async () => {
        await this.search();
      }, 200);
    },
  },

  computed: {
    ...mapState("userProfile", ["dateFormat"]),

    searchLabel() {
      let partyTypeId = this.selectedPartyTypeId;
      let p = partyTypeList.find(pt => pt.id === partyTypeId);

      return searchUtilsService.getLabel(p ? `${p.name}s` : "Parties", this.totalRecords);
    },

    networkMatchesDialogTitle() {
      if (this.networkMatchesLoading) return "Finding Matches on the Exchange Network...";

      if (this.networkMatches.length === 0) return "Sorry no matches found";

      return this.networkMatches.length === 1 ? "1 match found" : `${this.networkMatches.length} matches found`;
    },

    enhancedParties: function() {
      return this.parties.map(party => ({
        isSelectable: partyStatusService.isNotOnNetwork(party),
        ...party,
      }));
    },
  },

  methods: {
    async init() {
      this.headers[5].text = terminologyService.lookup(terminology.ORG_UNIT);

      partyTypeListWithTerminology.forEach(pt => {
        if (pt.terminologyId) pt.name = terminologyService.lookup(pt.terminologyId);
      });

      let options = localStorageService.get("partyList", {
        page: 1,
        itemsPerPage: 50,
        sortDesc: [false],
        sortBy: ["name"],
      });
      this.tableOptions = options;
      this.searchQuery = options.searchQuery;
      this.selectedPartyTypeId = options.partyTypeId || -1;
      this.initialized = true;
      this.selectedConnectionStatus = options.connectionStatus || "";
      this.search();
    },

    partyTypeNameWithTerminology(partyTypeId, partyTypeName) {
      return partyTypeId === partyType.VENDOR ? terminologyService.lookup(terminology.VENDOR) : partyTypeName;
    },

    async networkSearch() {
      this.networkMatches = [];
      this.showNetworkMatchesDialog = true;
      this.networkMatchesLoading = true;
      this.networkMatches = await partyService.networkSearch();
      this.networkMatchesLoading = false;
      this.search();
    },

    async doneAction() {
      this.search();
    },

    async connectedToParty() {
      this.search();
    },

    async invite(party) {
      try {
        party.isInviteSent = true;
        await partyService.invite(party.id);
        await this.search();
      } catch (err) {
        this.$root.$snackbar.exception(err);
        party.isInviteSent = false;
      }
    },

    async inviteParties() {
      await Promise.all(
        this.enhancedSelectedParties.map(async p => {
          let party = this.parties.find(party => party.id === p.id);
          if ((party.emails.length === 0) || (party.emails[0] !== p.inviteEmailAddress))
            party.emails = [p.inviteEmailAddress];

          let partyCheckedUp = await partyService.checkupPartyAlreadyConnected(party);
          await partyService.update(partyCheckedUp);
          await partyService.invite(partyCheckedUp.id);
        })
      ).catch(e => this.$root.$snackbar.exception(e));

      this.showInviteDialog = false;
      this.parties = [];
      this.selectedParties = [];

      await this.search();
    },

    async showInvitePartiesDialog() {
      this.enhancedSelectedParties = this.selectedParties.map(party => ({
        inviteEmailAddress: party.emails.length === 0 ? "" : party.emails[0],
        ...party,
      }));

      this.showInviteDialog = await this.showConfirmDialog();
    },

    async connect(party) {
      let answer = this.showConfirmDialog();

      if (answer) {
        party.isInviteSent = true;
        await partyService.connect(party.id);
        await this.search();
      }
    },

    async showConfirmDialog() {
      return await this.$root.$confirmDialog.open(
        "Accept",
        "By clicking continue, you agree that you have done any applicable due diligence specific to your company policies relevant to accepting documents from outside parties",
        "Continue"
      );
    },

    saveFiltersAndPaging() {
      localStorageService.save("partyList", this.tableOptions, {
        searchQuery: this.searchQuery,
        partyTypeId: this.selectedPartyTypeId,
        connectionStatus: this.selectedConnectionStatus,
      });
    },

    async search() {
      if (!this.initialized) return;

      const result = await partyService.search(
        false,
        this.searchQuery,
        this.selectedPartyTypeId,
        this.selectedConnectionStatus,
        this.tableOptions
      );
      this.parties = result.items;
      this.totalRecords = result.totalRecords;
      this.loading = false;
    },

    async addNew() {
      let result = await this.$root.$inputListDialog.showListOptions("New Party", [
        { icon: "mdi-plus", name: "Create Manually" },
        { icon: "mdi-file-excel", name: "Import from Excel" },
      ]);

      this.$router.push(
        result.index === 0 ? `/admin/party/create?partyTypeId=${this.selectedPartyTypeId}` : "/admin/parties/import"
      );
    },

    selectParty(party) {
      this.$router.push(`/admin/party/${party.id}`);
    },
  },
};
</script>
