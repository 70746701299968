<template>
  <v-container fluid>
    <v-card class="elevation-1 card pa-4 card-form" v-if="!loading">
      <v-form ref="form">
        <v-card-text>
          <v-row>
            <h3 class="headline mb-4 ml-2">{{ isCreate ? "Create" : "" }} Party</h3>
            <v-spacer />
            <div v-show="!isCreate">
              <v-row>
                <v-col><PartyStatus :party="party"/></v-col>
                <v-col><ActionDropdown :actions="actions" :performAction="performAction"/></v-col>
              </v-row>
            </div>
          </v-row>
          <v-row class="pa-2 mb-4 invite" v-show="isCreate">
            <v-checkbox v-model="party.inviteToNetwork" label="Invite party to join the Exchange Network"></v-checkbox>
          </v-row>
          <EntityForm :entity="party" :inputFields="PARTY_FIELDS" />
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="save()" :disabled="loading" large color="primary" depressed>Save</v-btn>
        <v-btn @click="cancel()" :disabled="loading" large depressed>Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <LoadingProgress :loading="loading" />
  </v-container>
</template>

<script>
import partyService from "@/services/partyService";
import partyTypeList from "@/constants/partyTypeList";
import partyType from "@/constants/partyType";
import validationService from "@/services/validationService";
import Auth0Mixin from "../Auth0Mixin";
import EntityForm from "@/components/EntityForm";
import ActionDropdown from "@/components/ActionDropdown";
import orgUnitService from "@/services/admin/orgUnitService";
import PartyStatus from "@/components/PartyStatus";
import terminologyService from "@/services/terminologyService.js";
import terminology from "@/constants/terminology";
import LoadingProgress from "@/components/LoadingProgress";
import serviceLookupServices from "@/services/servicesLookupService";
import ValidationRules from "../../validationRules";
import country from "@/constants/country";

const partyTypeListWithTerminology = JSON.parse(JSON.stringify(partyTypeList));

const ACTION_DELETE = "Delete";
const ACTION_SAVE = "Save";

const PARTIES_URL = "/admin/parties";

const requiredRule = [v => !!v || "Required"];

const orgUnitField = { label: "", attribute: "companyOrgUnitIds", type: "multi-select", items: [] };
const CONVERT_TO_LOOKUP = serviceData => ({ id: serviceData.code, name: serviceData.name });

const PARTY_FIELDS = [
  { label: "Type", attribute: "partyTypeId", rules: requiredRule, type: "select", items: partyTypeListWithTerminology },
  { label: "Name", attribute: "name", rules: requiredRule, type: "text" },
  { label: "Known As", attribute: "nameTwo", type: "text", maxlength: 250 },
  { label: "External System", attribute: "externalSystem", type: "text", maxlength: 128, readonly: true },
  { label: "External System ID", attribute: "externalSystemId", type: "text", maxlength: 32, readonly: true },
  { label: "Party Number", attribute: "number", rules: requiredRule, type: "text" },
  orgUnitField,
  { label: "ACH Routing Transit Number", attribute: "achRoutingTransitNumber", type: "text", maxlength: 9 },
  { label: "Wire Routing Transit Number", attribute: "wireRoutingTransitNumber", type: "text", maxlength: 9 },
  { label: "Effective From Date", attribute: "effectiveFromDate", type: "date", showDatePicker: false },
  { label: "Effective Thru Date", attribute: "effectiveThruDate", type: "date", showDatePicker: false },

  // Contact
  { label: "Contact", attribute: "contact", type: "header" },
  {
    label: "Emails",
    attribute: "emailsAsString",
    rules: requiredRule,
    type: "text",
    maxlength: 500,
    hint: "Specify multiple emails with spaces. For example: john@mycompany.com sue@mycompany.com",
  },
  { label: "Phone number", attribute: "phone", type: "text", maxlength: 16 },
  { label: "Extension", attribute: "ext", type: "text", maxlength: 8 },
  { label: "Cell Phone Number", attribute: "cell", type: "text", maxlength: 16 },

  // Primary Address
  { label: "Primary Address", attribute: "primaryAddress", type: "header" },
  { label: "Address Line One", attribute: "addressLineOne", type: "text" },
  { label: "Address Line Two", attribute: "addressLineTwo", type: "text" },
  { label: "Address Line Three", attribute: "addressLineThree", type: "text" },
  { label: "Country", attribute: "country", type: "select" },
  { label: "State", attribute: "state", type: "select" },
  { label: "City", attribute: "city", type: "text" },
  { label: "Zip Code", attribute: "zipCode", type: "text" },

  // Secondary Address
  { label: "Secondary Address", attribute: "secondaryAddress", type: "header" },
  { label: "Address Line One", attribute: "altAddressLineOne", type: "text" },
  { label: "Address Line Two", attribute: "altAddressLineTwo", type: "text" },
  { label: "Address Line Three", attribute: "altAddressLineThree", type: "text" },
  { label: "Country", attribute: "altCountry", type: "select" },
  { label: "State", attribute: "altState", type: "select" },
  { label: "City", attribute: "altCity", type: "text" },
  { label: "Zip Code", attribute: "altZipCode", type: "text" },
  { label: "Active", attribute: "isActive", type: "checkbox" },
  // Tax
  { label: "Tax", type: "header" },
  { label: "Tax Identification Type", attribute: "taxTypeId", type: "select" },
  { label: "Tax Identifier", attribute: "taxIdentifier", rules: requiredRule, type: "text", maxlength: 50 },
  { label: "Tax ID Valid", attribute: "isTaxValid", type: "checkbox", disabled: true },
];

const TAX_TYPE_FIELD = PARTY_FIELDS.findIndex(x => x.attribute === "taxTypeId");
const TAX_NUMBER_FIELD = PARTY_FIELDS.findIndex(x => x.attribute === "taxIdentifier");
const COUNTRY_FIELD = PARTY_FIELDS.findIndex(x => x.attribute === "country");
const STATE_FIELD = PARTY_FIELDS.findIndex(x => x.attribute === "state");
const ALT_STATE_FIELD = PARTY_FIELDS.findIndex(x => x.attribute === "altState");
const ALT_COUNTRY = PARTY_FIELDS.findIndex(x => x.attribute === "altCountry");
const ZIP_FIELD = PARTY_FIELDS.findIndex(x => x.attribute === "zipCode");
const ALT_ZIP_FIELD = PARTY_FIELDS.findIndex(x => x.attribute === "altZipCode");

export default {
  name: "Party",
  mixins: [Auth0Mixin],
  components: { EntityForm, ActionDropdown, PartyStatus, LoadingProgress },
  data() {
    return {
      party: null,
      isCreate: false,
      loading: false,
      actions: [ACTION_SAVE, ACTION_DELETE],
      countries: [],
      allStates: [],
      allTaxes: [],
      PARTY_FIELDS,
      partyTypeListWithTerminology,
    };
  },

  methods: {
    async init() {
      this.loading = true;
      partyTypeListWithTerminology.forEach(pt => {
        if (pt.terminologyId) pt.name = terminologyService.lookup(pt.terminologyId);
      });

      orgUnitField.label = terminologyService.lookup(terminology.ORG_UNIT);
      this.isCreate = this.$route.params.id === "create";

      PARTY_FIELDS[0].readonly = !this.isCreate;
      this.countries = (await serviceLookupServices.getCountries()).map(CONVERT_TO_LOOKUP);
      this.allStates = await serviceLookupServices.getAllStatesByCountry();
      this.allTaxes = await serviceLookupServices.getAllTaxTypesByCountry();
      this.PARTY_FIELDS[COUNTRY_FIELD].items = this.countries;
      this.PARTY_FIELDS[ALT_COUNTRY].items = this.countries;
      this.PARTY_FIELDS[COUNTRY_FIELD].changeEvent = this.onCountryChange;
      this.PARTY_FIELDS[ALT_COUNTRY].changeEvent = this.onAltCountryChange;
      this.PARTY_FIELDS[TAX_TYPE_FIELD].changeEvent = this.onTaxTypeChange;

      if (this.isCreate) {
        let partyTypeId = parseInt(this.$route.query.partyTypeId);
        this.party = {
          partyTypeId: partyTypeId > 0 ? partyTypeId : partyType.VENDOR,
          isActive: true,
          emailsAsString: "",
          inviteToNetwork: false,
          emails: [],
        };
      } else {
        this.party = await partyService.get(this.$route.params.id);
        this.party.emailsAsString = this.party.emails.join(" ");
        this.party.effectiveFromDate = this.party.effectiveFromDate ? this.party.effectiveFromDate.substr(0, 10) : null;
        this.party.effectiveThruDate = this.party.effectiveThruDate ? this.party.effectiveThruDate.substr(0, 10) : null;
        if (this.party.country) this.onCountryChange(true);
        if (this.party.altCountry) this.onAltCountryChange(true);
      }

      const result = await orgUnitService.getList(true);
      orgUnitField.items = result.items
        .filter(orgUnit => orgUnit.isActive === true)
        .map(o => ({ id: o.id, name: o.name }));

      this.loading = false;
    },
    onCountryChange(keepValue) {
      if (!keepValue) this.party.state = undefined;
      this.PARTY_FIELDS[STATE_FIELD].items = this.allStates[this.party.country].map(CONVERT_TO_LOOKUP);
      this.PARTY_FIELDS[TAX_TYPE_FIELD].items = this.allTaxes[this.party.country].map(x => ({ id: x.Code, name: x.Name }));
      this.PARTY_FIELDS[ZIP_FIELD].rules =
        this.party.country === country.CA ? [ValidationRules.postalCodeRule] : [ValidationRules.zipCodeRule];
      this.PARTY_FIELDS[ZIP_FIELD].label = this.party.country === country.CA ? "Postal Code" : "Zip Code";
    },
    onAltCountryChange(keepValue) {
      if (!keepValue) this.party.altState = undefined;
      this.PARTY_FIELDS[ALT_STATE_FIELD].items = this.allStates[this.party.altCountry].map(CONVERT_TO_LOOKUP);
      this.PARTY_FIELDS[ALT_ZIP_FIELD].rules =
        this.party.altCountry === country.CA ? [ValidationRules.postalCodeRule] : [ValidationRules.zipCodeRule];
      this.PARTY_FIELDS[ALT_ZIP_FIELD].label = this.party.altCountry === country.CA ? "Postal Code" : "Zip Code";
    },
    onTaxTypeChange() {
      this.PARTY_FIELDS[TAX_NUMBER_FIELD].hint = this.party.taxTypeId ?
        this.allTaxes[this.party.country].find(x => x.Code === this.party.taxTypeId).Format : null;
    },
    async performAction(action) {
      if (action === ACTION_DELETE) {
        await partyService.delete(this.party.id);
        this.$root.$snackbar.message(`Party Deleted`);
        this.$router.push(PARTIES_URL);
      }
      if (action === ACTION_SAVE) {
        this.save();
      }
    },

    convertEmailsToList() {
      let es = this.party.emailsAsString.trim();
      if (es.length === 0) {
        this.party.emails = [];
        return true;
      }

      var emails = es.split(/\s+/);

      let invalidEmail = emails.find(email => !validationService.isvalidEmail(email));

      if (invalidEmail != undefined) {
        this.$root.$snackbar.error(`${invalidEmail} is not a valid email`);
        return false;
      }

      this.party.emails = emails;
      return true;
    },

    async save() {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      if (this.party.effectiveFromDate && this.party.effectiveThruDate) {
        if (!validationService.isValidDateRange(this.party.effectiveFromDate, this.party.effectiveThruDate)) {
          window.scrollTo(0, 0);
          this.$root.$snackbar.error("Effective From Date must be before Effective Thru Date");
        }
      }

      if (!this.party.companyOrgUnitIds || this.party.companyOrgUnitIds.length === 0) {
        window.scrollTo(0, 0);
        this.$root.$snackbar.error("You must select at least 1 " + terminologyService.lookup(terminology.ORG_UNIT));
        return;
      }

      if (!this.convertEmailsToList()) {
        return;
      }

      this.loading = true;

      try {
        if (this.isCreate) {
          if (this.party.inviteToNetwork && this.party.emails.length === 0) {
            this.loading = false;
            this.$root.$snackbar.error("A contact email is required to invite a party to your network");
            return;
          }

          await partyService.create(this.party);
        } else {
          await partyService.update(this.party);
        }
      } catch (err) {
        this.loading = false;
        this.$root.$snackbar.exception(err);
        return;
      }

      this.$root.$snackbar.message(`Party ${this.isCreate ? "Created" : "Saved"}`);
      this.$router.push(PARTIES_URL);
    },
    cancel() {
      this.$router.push(PARTIES_URL);
    },
  },
};
</script>
<style scoped>
.invite {
  border: 2px solid #eee;
}
</style>
